var CaseStudiesSmall = {
    setupSwipeSupport: function () {
        $('.CaseStudiesSmall-carousel').each(function () {
            var $carousel = $(this),
                hammertime = new Hammer(this, {
                    recognizers: [
                        [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]
                    ]
                });
            hammertime.on('swipeleft', function () {
                $carousel.carousel('next');
            });

            hammertime.on('swiperight', function () {
                $carousel.carousel('prev');
            });
        });
    },

    init: function () {
        CaseStudiesSmall.setupSwipeSupport();
    }
}
$(function () {
    CaseStudiesSmall.init();
});